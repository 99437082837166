import React, { useState, useEffect } from "react";
import ReportQuiz from "./ReportQuiz";
import ButtonCloseX from "./ButtonCloseX";

const Modal = ({ results, handleClose, show }) => {
    const [jsonResults, setJsonResults] = useState({});
    const showHideClassName = show ? "block" : "hidden";

    useEffect(() => {
        console.log("refresh modal report");
        if (results !== null && results !== undefined && results !== "") {
            console.log(results);
            setJsonResults(JSON.parse(results));
        }
    }, [results]);


    return (
        <div class={`absolute bg-neutral-950/75 left-0 top-0 w-full h-full ${showHideClassName}`}>
            {/* <button type="button" class='rounded-full bg-neutral-950 hover:bg-neutral-800 border-neutral-100 border-2 z-50 text-neutral-100 absolute top-10 right-10 p-3 h-12 w-12' onClick={handleClose}>
                X
            </button>             */}
            <ButtonCloseX
                classes="rounded-full bg-neutral-950 hover:bg-neutral-800 border-neutral-100 border-2 z-50 text-neutral-100 absolute top-10 right-10 p-3 h-12 w-12"
                handleClick={handleClose}>X</ButtonCloseX>
            <section class={`absolute bg-slate-50 w-auto max-w-5/6 max-h-[80%] p-1 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  rounded-xl overflow-y-scroll overflow-x-hidden`}>
                {
                    jsonResults !== undefined && Object.keys(jsonResults).length > 0
                        ?
                        <ReportQuiz results={jsonResults}></ReportQuiz>
                        :
                        <p>Risultato del quiz assente.</p>
                }
            </section>
        </div>
    );
}

export default Modal;