import React from 'react';
import { FormControl, Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import './css/modal.css';

const ModalFormInsertStudente = ({ handleConfirmButton, lobbies }) => {
    
    const handleChange = (d) => {
        localStorage.setItem('lobbyName', d);
    }

    if (lobbies !== undefined && lobbies.length > 0) {
        return (
            <>
                <form onSubmit={handleConfirmButton}>
                    <FormControl>
                        <h2 class="font-bold my-3 text-xl">Seleziona una sessione e inserisci il nome utente</h2>
                        <label class="block text-gray-700 text-sm font-bold mb-2" htmlFor="radio-buttons-group">
                            Seleziona la sessione a cui vuoi accedere
                        </label>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            name="rbInstances">
                            {
                                lobbies.map((lobby) => {
                                    if (lobby.description !== undefined && lobby.description !== "") {
                                        return (
                                            <FormControlLabel key={lobby.instanceCode} onChange={()=> handleChange(lobby.description)} 
                                             value={lobby.instanceCode} control={<Radio />} label={lobby.description} />
                                        );
                                    }else{
                                        return (<></>);
                                    }
                                })
                            }
                        </RadioGroup>

                        <label class="block text-gray-700 text-sm font-bold mb-2 mt-5" htmlFor="username">
                            Inserisci il nome utente
                        </label>
                        <input class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            name="username"
                            type="text"
                            placeholder="Username" />
                        <Button sx={{ mt: 1, mr: 1 }} type="submit" class='bg-sky-700 hover:bg-sky-600 py-2 mt-4 rounded-2xl text-neutral-100 w-full'>
                            Conferma
                        </Button>


                    </FormControl>
                </form>
            </>
        );
    } else {
        return (
            <h2 class="font-bold my-3 text-xl">Non sono presenti sessioni per questo Tour</h2>
        )
    }
}

export default ModalFormInsertStudente;