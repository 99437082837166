import React from 'react';
import { FormControl, Button } from "@material-ui/core";
import './css/modal.css';

const ModalFormInsertTutor = ({ handleConfirmButton }) => {

    return (
        <form onSubmit={handleConfirmButton}>
            <FormControl>
                <h2 class="font-bold my-3 text-xl">Inserisci il nome nome della sessione per questo tour</h2>
                <>
                    <label class="block text-gray-700 text-sm font-bold mb-2" htmlFor="sessionName">
                        Nome
                    </label>
                    <input class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="sessionName"
                        name="sessionName"
                        type="text"
                        placeholder="Sessione" />
                    <Button sx={{ mt: 1, mr: 1 }} type="submit" class='bg-sky-700 hover:bg-sky-600 py-2 mt-4 rounded-2xl text-neutral-100 w-full'>
                        Avvia
                    </Button>
                </>

            </FormControl>
        </form>
    );
}

export default ModalFormInsertTutor;