import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";

const TopBar = () => {
    let navigate = useNavigate();

    const HandleLogout = () => {
        localStorage.removeItem("userUCode");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userLevel");
        localStorage.removeItem("userLoggedInfo");
        localStorage.removeItem("userName");
        localStorage.removeItem("customerCode");
        localStorage.removeItem("tourData");
        localStorage.removeItem("quiz");
        localStorage.removeItem("startTime");
        localStorage.removeItem("endTime");
        localStorage.removeItem("refresh");
        navigate('/signin');
    }

    return (
        <nav className="bg-white border-gray-200">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                {/* <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse"> */}
                <img src={logo} className="h-8" alt="EDU360VR" />
                {/* </a> */}
                <button data-collapse-toggle="navbar-multi-level" type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                    aria-controls="navbar-multi-level" aria-expanded="false">
                    <span className="sr-only">Menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto" id="navbar-multi-level">
                    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white  ">
                        <li>
                            <a href="/" onClick={HandleLogout} className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 ">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default TopBar;