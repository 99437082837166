import React from 'react';

const FormLogin = ({handleLogin}) => {
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center content-center bg-gradient-to-bl from-zinc-900 via-gray-800 to-stone-900">
            <form onSubmit={handleLogin} className="bg-neutral-100 rounded-xl p-10 mb-4 shadow-[0.625rem_0.625rem_0.875rem_0_rgb(20,20,20),-0.5rem_-0.5rem_1.125rem_0_rgb(45,45,45)] border-[1px] border-stone-500">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                        Email
                    </label>
                    <input className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="username" 
                    name="username" 
                    type="text" 
                    placeholder="Username@email.com" />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                        Password
                    </label>
                    <input className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    id="password" 
                    name="password" 
                    type="password" 
                    placeholder="********" />
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-sky-700 hover:bg-sky-800 text-white font-bold py-2 px-4 rounded-2xl w-full focus:outline-none focus:shadow-outline" type="submit">
                        Accedi
                    </button>
                </div>
            </form>
        </div>
    );
}

export default FormLogin;