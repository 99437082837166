import React from "react";
import './css/modal.css';

const ModalContentText = ({content}) => {
    return (
        <>
            {content}
        </>
    );
   
}

export default ModalContentText;