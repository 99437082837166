import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import TourPreviewTile from "./TourPreviewTile";
import TopBar from "./TopBar";

const VirtualTourGrid = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const constants = location.state.constants;
    const [preview, setPreview] = useState([]);
    const [categories, setCategories] = useState([]);


    const getCatgory = (code) => {
        for (let c of categories)
            if (c.UCode === code)
                return c.CategoryName;
    }

    useEffect(() => {
        let token = localStorage.getItem('userToken');
        let userUCode = localStorage.getItem('userUCode');

        // acquisisco le immagini della gallery specifica
        axios.get(constants.API_BASE_URL + '/api/virtualtour/getPreviews/' + userUCode, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            if (response.status === 200) {
                //console.log(response.data);
                setPreview(response.data.VirtualTourPreviews);
                setCategories(response.data.Categories)
            }
        }).catch((error) => {
            if (error.request.status === 401) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userLevel');
                localStorage.removeItem('userUCode');
                localStorage.removeItem("quiz");
                localStorage.removeItem("startTime");
                localStorage.removeItem("endTime");
                localStorage.removeItem("refresh");
                navigate('/signin', { state: { constants: constants } });
            }
            else
                console.error("error.message");
        });

        // rimuovo la classe a-fullscreen che inietta AFrame sul tag html della pagina
        var htmlRoot = document.getElementById('htmlRoot');
        if (htmlRoot.classList.contains('a-fullscreen')){
            htmlRoot.classList.remove('a-fullscreen');
        }
    }, []);


    const previewTiles = preview.map((vt) => {
        return (
            <TourPreviewTile
                key={vt.UCode}
                Constants={constants}
                TourCode={vt.UCode}
                SetSelectedTourCode={props.setSelectedTourCode}
                SetBackgroundVR={props.setBackgroundVR}
                SetRotationVR={props.setRotationVR}
                BgTrack={vt.BackgroundTrack}
                SetBgTrack={props.setBgTrack}
                SetMenuActive={props.setMenuActive}
                SetTourData={props.setTourData}
                TourName={vt.VirtualTourName}
                Category={getCatgory(vt.CategoryUCode)}
                Type={vt.ExperienceType}
                Cover={vt.Cover}
                CurrentImageUcode={props.currentImageUcode}
                SetCurrentImageUcode={props.setCurrentImageUcode}
                SetMessageToSend={props.setMessageToSend}
                instanceCode={props.instanceCode}
                lobbies={props.lobbies} />

        );
    });

    return (
        <>
            <TopBar />
            <div className="bg-gradient-to-bl from-zinc-900 via-gray-800 to-stone-900 flex flex-wrap justify-center content-start gap-10 h-full p-10">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-neutral-100 md:text-5xl lg:text-6xl dark:text-white">I tuoi tour 360</h1>
                <p className="mb-6 text-lg font-normal text-neutral-400 lg:text-xl sm:px-16 xl:px-48">Qui di seguito trovi una griglia con i tour associati alla tua utenza. Clicca su un riquadro per accedere al tour 360 corrispondente.</p>
                {previewTiles}
            </div>
        </>
    );
}

export default VirtualTourGrid;