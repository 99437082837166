import React, { useEffect } from "react";
import moment from 'moment';
import '../App.scss';

//restartHandleClick
const ReportQuiz = ({ results }) => {

  let questions = results;

  /**
   * RISPOSTE SALVATE
   * unanswered => l'utente ha cliccato su "SALTA" e quindi ha deciso di non ripondere alla domanda
   * "" => L'utente per qualche motivo è riuscito a saltare la domanda (magari è stato il tutor a fargliela saltare)
   * OK e KO => si spiegano da soli...
   */

  const jUnanswrd = questions.filter((u) => u.status === "unanswered");
  const jNull = questions.filter((u) => u.status === "");
  const jOk = questions.filter((u) => u.status === "OK");
  const jKo = questions.filter((u) => u.status === "KO");
  let n_unanswered = jUnanswrd.length;
  let n_null = jNull.length;
  let totAnswers = questions.length;
  let okAnswers = jOk.length;
  const startTime = moment(localStorage.getItem('startTime'));
  const endTime = moment(localStorage.getItem('endTime'));
  let msElapsed = Math.abs(endTime - startTime);
  let minutes = Math.floor((msElapsed / 1000) / 60);

  useEffect(() => {
    console.log("refresh report");
    console.log(results);
  }, [results]);

  return (
    <>
      <div class='p-10 text-center h-auto bg-gradient-to-bl from-zinc-900 via-gray-800 to-stone-900 rounded-lg'>
        <div class="text-center h-full m-auto px-20">
          <h1 class="text-neutral-100 font-extrabold my-8 mx-10 text-3xl">Quiz Terminato</h1>
          <p class="text-neutral-300 font-medium my-6 mx-10 text-base">Tempo impiegato: {minutes} minuti.</p>
          <h3 class="text-sky-400 font-bold my-6 mx-10 text-xl">Hai risposto correttamente a {okAnswers} domande su {totAnswers}.</h3>
          {
            n_unanswered !== undefined && n_unanswered > 0
              ?
              <h3 class="text-yellow-400 font-bold my-6 mx-10 text-xl">Hai scelto di non rispondere a {n_unanswered} domande.</h3>
              :
              null
          }
          {
            n_null !== undefined && n_null > 0
              ?
              <h3 class="text-neutral-400 font-bold my-6 mx-10 text-xl">{n_null} domande saltate.</h3>
              :
              null
          }
          {
            <>

              <p class="text-neutral-300 font-medium my-6 mx-10 text-base">Ecco i dettagli delle tue risposte: </p>
              <div class='py-4 px-8 bg-neutral-200 rounded-xl mx-auto mt-8 mb-0 text-left min-h-[100px] shadow-[0.625rem_0.625rem_0.875rem_0_rgb(20,20,20),-0.5rem_-0.5rem_1.125rem_0_rgb(45,45,45)] border-[1px] border-stone-500'>
                {
                  questions.map((el, index) => {
                    return (
                      <div key={index}>
                        <div class='text-sky-700 text-xl mt-4'>
                          <div class={`w-[20px] h-[20px] rounded-full mr-4 inline-flex  ${el.status === "OK" ? "bg-emerald-700" : el.status === "KO" ? "bg-red-600" : el.status === "unanswered" ? "bg-yellow-400" : "bg-neutral-400"}`}></div>
                          {index + 1} - {el.question.toString()}
                        </div>
                        {
                          el.status === "unanswered"
                            ?
                            <>
                              <p class="text-neutral-900 font-medium my-1 mx-10 text-base"><span style={{ marginLeft: 30 }} class="text-neutral-600">Non hai risposto</span></p>
                              <div style={{ marginLeft: 30 }}>
                                <p class="text-neutral-900 font-medium my-1 mx-10 text-base">
                                  <span class="text-neutral-600">La risposta corretta è</span>: {el.answerOk}
                                </p>
                                <p class="text-neutral-900 font-medium my-1 mx-10 text-base"><span class="text-neutral-600">Suggerimento</span>: {el.reference}</p>
                              </div>
                            </>
                            :
                            <>
                              {
                                el.status === "OK"
                                  ?
                                  <>
                                    <p class="text-neutral-900 font-medium my-1 mx-10 text-base"><span style={{ marginLeft: 30 }} class="text-neutral-600">Hai risposto</span>: {el.givenAnswer}</p>
                                    <div style={{ marginLeft: 30 }}>
                                      <p class="text-neutral-950 mx-10 text-lg">Risposta corretta.</p>
                                    </div>
                                  </>
                                  :
                                  el.status === "KO" ?
                                    <>
                                      <p class="text-neutral-900 font-medium my-1 mx-10 text-base"><span style={{ marginLeft: 30 }} class="text-neutral-600">Hai risposto</span>: {el.givenAnswer}</p>
                                      <div style={{ marginLeft: 30 }}>
                                        <p class="text-neutral-950 mx-10 text-lg">Risposta errata!</p>
                                        <p class="text-neutral-900 font-medium my-1 mx-10 text-base">
                                          <span class="text-neutral-600">La risposta corretta è</span>: {el.answerOk}
                                        </p>
                                        <p class="text-neutral-900 font-medium my-1 mx-10 text-base"><span class="text-neutral-600">Suggerimento</span>: {el.reference}</p>
                                      </div>
                                    </>
                                    :
                                    <div style={{ marginLeft: 30 }}>
                                      <p class="text-neutral-950 mx-10 text-lg">Domanda saltata!</p>
                                    </div>
                              }
                            </>
                        }

                      </div>
                    )
                  })
                }
              </div>

            </>
          }
        </div >
      </div >
    </>
  )
}

export default ReportQuiz;