import React from "react";

const ButtonCloseX = ({classes, handleClick, children}) => {
    return (
        <>
            <button type="button" class={classes} onClick={handleClick}>
               {children} 
            </button>
        </>
    );
   
}

export default ButtonCloseX;