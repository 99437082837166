import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FormLogin from '../components/FormLogin.jsx';


function Signin({ setMessageToSend }) {

  const location = useLocation();
  const navigate = useNavigate();
  // variabili di stato
  const [constants, setConstants] = useState(location.state.constants);
  const [loggedUser, setLoggedUser] = useState('');
  const [username, setUsername] = useState('');
  const [userlevel, setUserlevel] = useState(null);
  const [token, setToken] = useState('');
  ////////////////////////////////////////

  useEffect(() => {
    //pulizia di un'eventuale record presente nel local storage
    //Cookies.remove(currentTourCode);
    localStorage.removeItem("quiz");
    localStorage.removeItem("startTime");
    localStorage.removeItem("endTime");
    localStorage.removeItem("refresh");
    const goToVirtuaTour = () => navigate('/');
    if (localStorage.getItem('userUCode') !== null) {
      console.log("Utente loggato: " + localStorage.getItem('userUCode'));
      goToVirtuaTour();
      //return <Navigate to='/virtualtour' />
    }

  }, [loggedUser]);

  const handleLoginClick = (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    axios.post(constants.API_BASE_URL + '/api/auth/loginapp/', {
      Username: formJson.username,
      Password: formJson.password
    }).then(response => {
      if (response.status === 200) {
        const resp = response.data;
        
        if (resp.isEnabled) {
          const loginMsg = {
            type: "login",
            payload: {
              "customerCode": response.data.customerCode,
              "userCode": response.data.uCode
            }
          }

          // [USERNAME,  PASSWORD in Base64, LOBBY, SCENA]
          let userLoggedInfo = [formJson.username, btoa(formJson.password), "", ""];
          localStorage.setItem('userLoggedInfo', JSON.stringify(userLoggedInfo));

          setUsername(resp.userName);

          localStorage.setItem('userToken', resp.token);
          setToken(resp.token);

          localStorage.setItem('userLevel', resp.userRoles);
          setUserlevel(resp.userRoles);

          localStorage.setItem('userUCode', resp.uCode);
          setLoggedUser(resp.uCode);

          localStorage.setItem('customerCode', resp.customerCode);

          setMessageToSend(JSON.stringify(loginMsg));

          navigate('/virtualtour', { state: { constants: constants } });
        
        } else {
          alert("Utente non abilitato");
        }
      } else {
        alert("Login fallito");
      }

    }).catch((e) => {
      alert("Login fallito");
      console.error(e);
    });
  }

  //////////////////////////////////////////
  // HTML
  return (
    <div className="App">
      <Routes>
        <Route
          path='/'
          element={
            loggedUser !== ''
              ?
              <div className='p-10 text-center bg-gradient-to-bl from-zinc-900 via-gray-800 to-stone-900 h-screen'></div>
              :
              <FormLogin handleLogin={handleLoginClick} />
          }
        />
      </Routes>
    </div>
  );
}

export default Signin;
