import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VirtualTour from './components/VirtualTour'
//import ReportQuiz from './components/ReportQuiz';
import Signin from './screen/Signin';
import VirtualTourGrid from './components/VirtualTourGrid';
import WebSocketWrapper from './wrappers/WebSocketWrapper';
import { compileString } from 'sass';
import axios from 'axios';
//import './App.scss';


function App(props) {
  const navigate = useNavigate();

  // variabili di stato
  const [constants, setConstants] = useState(props._constants);
  const [connected, setConnected] = useState(false);
  const [messageToSend, setMessageToSend] = useState("");
  const [lobbies, setLobbies] = useState([]);
  const [usersInLobby, setUsersInLobby] = useState([]);
  const [currentImageUcode, setCurrentImageUcode] = useState("");
  const [instanceCode, setInstanceCode] = useState("");
  const [selectedTourCode, setSelectedTourCode] = useState("");
  const [backgroundVR, setBackgroundVR] = useState("");
  const [bgTrack, setBgTrack] = useState("");
  const [rotationVR, setRotationVR] = useState("0 0 0");
  const [menuActive, setMenuActive] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertTitle, setAlertTitle] = useState("Errore");
  const [keyPressed, setKeyPressed] = useState('');
  const [tourData, setTourData] = useState({
    loading: false,
    currentGallery: "",
    images: [],
    hotspots: [],
  });

  // variabili di supporto
  let discoveryInterval;
  const discoveryTimeout = 5000;

  const joinMsg = {
    type: "join",
    payload: {
      instanceCode: "ced79c77-847b",
      userName: ""
    }
  }

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    if (keyPressed !== "F5" && keyPressed !== "r") {
      if (localStorage.getItem('userUCode') === null || localStorage.getItem('userUCode') === "") {
        //console.log("vai al login " + localStorage.getItem('userUCode'));
        goToLogin();
      }
    }
  };

  const removeDataFromLocalStorage = (removeTourDataOnly) => {
    if (!removeTourDataOnly) {
      localStorage.removeItem("userUCode");
      localStorage.removeItem("userName");
      localStorage.removeItem("userToken");
      localStorage.removeItem("userLevel");
      localStorage.removeItem("userLoggedInfo");
      localStorage.removeItem("customerCode");
    }
    localStorage.removeItem("tourData");
    localStorage.removeItem("quiz");
    localStorage.removeItem("startTime");
    localStorage.removeItem("endTime");
    localStorage.removeItem("refresh");
  }



  const goToLogin = () => {
    removeDataFromLocalStorage(false);
    navigate('/signin', { state: { constants: constants } });
  };



  useEffect(() => {

    //const goToReport = () => navigate('/report');

    //if (!tourRunning) goToReport();

    //GESTIONE DEL RELOAD
    if (localStorage.getItem('userUCode') !== null && performance.getEntriesByType("navigation")[0].type === "reload") {
      //console.log("UTENTE LOGGATO!");


      if (localStorage.getItem("userLoggedInfo") !== undefined && localStorage.getItem("userLoggedInfo") !== "") {

        // userLoggedInfo = [USERNAME,  PASSWORD in Base64, CODICE TOUR, LOBBY, CODICE SCENA]
        let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
        //console.log(userLoggedInfo);

        if (userLoggedInfo.length > 0) {
          //console.log("RIFACCIO LOGIN con password " + atob(userLoggedInfo[1]));
          //controllo se ci sono salvati email e password
          if (userLoggedInfo[0] !== "" && userLoggedInfo[1] !== "") {
            //rifaccio il login
            const b64DecodeUnicode = (str) => {
              // Going backwards: from bytestream, to percent-encoding, to original string.
              return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
            }
            let pass = b64DecodeUnicode(userLoggedInfo[1])
            axios.post(constants.API_BASE_URL + '/api/auth/loginapp/', {
              Username: userLoggedInfo[0],
              Password: pass
            }).then(response => {
              if (response.status === 200) {
                const resp = response.data;
                const loginMsg = {
                  type: "login",
                  payload: {
                    "customerCode": response.data.customerCode,
                    "userCode": response.data.uCode
                  }
                }

                localStorage.setItem('userToken', resp.token);
                localStorage.setItem('userLevel', resp.userRoles);
                localStorage.setItem('userUCode', resp.uCode);
                localStorage.setItem('customerCode', resp.customerCode);
                setMessageToSend(JSON.stringify(loginMsg));

                //navigate('/virtualtour', { state: { constants: constants } }); 

                //controllo se nell'array userLoggedInfo ci sono i codice tour [2]; instance code della lobby [3] e codice scena [4]

                setTimeout(() => {
                  if (userLoggedInfo[2] !== "") {
                    if (localStorage.getItem("tourData") !== null && localStorage.getItem("tourData") !== undefined && localStorage.getItem("tourData") !== "") {
                      let tdata = JSON.parse(localStorage.getItem("tourData"));
                      setTourData(tdata);
                      //console.log(tdata);


                      if (userLoggedInfo[3] !== "") {
                        const joinLobby = {
                          "type": "join",
                          "payload": {
                            instanceCode: userLoggedInfo[3],
                            userName: localStorage.getItem('userName')
                          }
                        }
                        //console.log(joinLobby);
                        setMessageToSend(JSON.stringify(joinLobby));

                      }

                      //console.log("entro nel tour " + userLoggedInfo[2]);
                      setSelectedTourCode(userLoggedInfo[2]);
                      if (userLoggedInfo[4] !== null && userLoggedInfo[4] !== undefined && userLoggedInfo[4] !== "") {
                        //console.log("vado alla scena ? " + userLoggedInfo[4]);
                        setCurrentImageUcode(userLoggedInfo[4]);
                        let specScene = tdata.images.filter((x) => x.uCode === userLoggedInfo[4])[0];
                        setBackgroundVR(constants.API_BASE_URL + "/Uploads/Corsi/" + userLoggedInfo[2] + "/" + specScene.backgroundContentPath);
                      } else {
                        setCurrentImageUcode(tdata.images[0].uCode);
                        setBackgroundVR(constants.API_BASE_URL + "/Uploads/Corsi/" + userLoggedInfo[2] + "/" + tdata.images[0].backgroundContentPath);
                      }
                    }
                  } else {
                    //console.log("nessuna lobby presente nel loggedInfo array");
                  }
                }, 500);


              } else {
                alert("Login fallito");
                removeDataFromLocalStorage(false);
                //navigate('/');
              }

            }).catch((e) => {
              alert("Errore di login");
              console.error(e);
              removeDataFromLocalStorage(false);
              //navigate('/');
            });
          }
        }
      } else {
        //pulizia di un'eventuale record presente nel local storage
        //Cookies.remove(currentTourCode);
        removeDataFromLocalStorage(true);
        navigate('/virtualtour', { state: { constants: constants } });
      }


    }
    else {

      goToLogin();
    }

  }, []);


  const handleBeforeUnload = (event) => {
    const storedUserLevel = JSON.parse(localStorage.getItem("userLevel"));
    //console.log(storedUserLevel);
    if (storedUserLevel.includes("Tutor"))
      event.preventDefault();

  };


  const resetLocalStorage = () => {
    if (localStorage.getItem("refresh") === null || localStorage.getItem("refresh") === undefined || localStorage.getItem("refresh") !== "1") {
      removeDataFromLocalStorage(false);
    }
  }

  useEffect(() => {
    window.addEventListener('unload', resetLocalStorage);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('unload', resetLocalStorage);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  function downHandler({ key }) {
    //console.log("SET KEY PRESSED " + key);
    if (key === "F5" || key === "r")
      localStorage.setItem("refresh", "1");
    setKeyPressed(key);
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [keyPressed]);


  //////////////////////////////////////////
  // WEBSOCKET
  //////////////////////////////////////////
  function onMessageReceived(message) {
    // scatenata ogni volta che ricevo un messaggio via websocket
    console.log("message type: " + message.type);

    switch (message.type) {
      // il client web è stato aggiunto alla lista delle connessioni attive
      case "lobbies":
        //console.log(message.payload);
        if (Array.isArray(message.payload)) {// && message.payload.length > 0
          let tmpLobbies = [];
          message.payload.map(el => {
            tmpLobbies.push(el);
          })

          //console.log(tmpLobbies)
          setLobbies(tmpLobbies);
        }
        break;

      // il server ha inviato l'elenco degli utenti in lobby
      case "users":
        if (Array.isArray(message.payload) && message.payload.length > 0) {
          let usersFound = [];
          message.payload.map(el => {
            usersFound.push(el);
          })
          //console.log(usersFound)
          setUsersInLobby(usersFound);
        }
        break;

      // il server ha inviato il codice della scena a cui andare
      case "changescene":
        //console.log(message.payload.sceneCode);
        let scena = tourData.images.filter((el) => el.uCode === message.payload.sceneCode);
        let uli = JSON.parse(localStorage.getItem("userLoggedInfo"));
        uli[4] = message.payload.sceneCode;
        localStorage.setItem("userLoggedInfo", JSON.stringify(uli));
        setCurrentImageUcode(message.payload.sceneCode);
        setBackgroundVR(constants.API_BASE_URL + "/Uploads/Corsi/" + scena[0].galleryUCode + "/" + scena[0].backgroundContentPath);
        break;

      // il server ha inviato l'istanza del tour con codice univoco per poter fare le join
      case "starttour":
        //console.log("ISTANZA " + message.payload.instanceCode)
        joinMsg.payload.instanceCode = message.payload.instanceCode;         // gli mando il codice della prima lobby, come test

        //se sono un TUTOR non mi interessa il nome, inserisco TUTOR staticamente
        //const storedUserLevel = JSON.parse(localStorage.getItem("userLevel"));
        // if (storedUserLevel.includes("Studente"))
        //   joinMsg.payload.userName = ""; // NOME INSERITO DAL FORM di JOIN
        // else
        joinMsg.payload.userName = "TUTOR";


        setMessageToSend(JSON.stringify(joinMsg));
        setInstanceCode(message.payload.instanceCode);

        let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
        userLoggedInfo[3] = message.payload.instanceCode;
        localStorage.setItem("userLoggedInfo", JSON.stringify(userLoggedInfo));

        navigate('/virtualtour/tour',
          {
            state: {
              constants: constants,
              tourcode: selectedTourCode,
              backgroundVR: backgroundVR,
              currentImageUcode: currentImageUcode,
              rotationVR: rotationVR,
              menuActive: menuActive,
              config: tourData,
              tourRunning: true,
              bgTrack: bgTrack,
            }
          });

        break;

      // il server invia a tutti il messaggio di chiusura dell'istanza del tour
      case "endtour":
        console.log("TOUR TERMINATO ");
        console.log(message.payload);
        break;

      case "error":
        setAlertTitle("Errore");
        switch (message.payload.code) {
          case "01":
            //utente non loggato
            localStorage.removeItem("userUCode");
            setAlertMsg("Sessione interrotta. Eseguire nuovamente il login.");
            handleClickOpenAlert();
            break;
          case "02":
            //lobby non esiste
            setAlertMsg("La sessione non esiste più.");
            handleClickOpenAlert();
            navigate('/virtualtour', { state: { constants: constants } });
            break;
          case "03":
            //lobby già presente
            setAlertMsg("Sessione già presente con questo nome.");
            handleClickOpenAlert();
            break;
          default:
            console.error(message);
            setAlertMsg(message.payload);
            handleClickOpenAlert();
            break;
        }

        break;

      default:
        console.log(message);
        // localStorage.removeItem("userUCode");
        // handleClickOpenAlert();
        break;


    }
  }

  function onConnectionChanged(connected) {
    // scatenata al cambio di stato della connessione
    // lo stato viene usato per gestire il mouseDrag sulla foto. se non sono connesso, è consentito il drag. altrimenti viene bloccato.
    //console.log("Connection changed -> " + connected);
    setConnected(connected);
    if (connected) {
      // TODO -> NON Inviare questo messaggio 
      //setMessageToSend(JSON.stringify(loginMsg)); //invio messaggio di login statico per testare sistema      

    } else {

      clearInterval(discoveryInterval);
      setMessageToSend("");

    }
  }
  //////////////////////////////////////////


  // HTML
  return (
    <div className="App">

      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleCloseAlert} color="primary">
            Chiudi
          </Button>

        </DialogActions>
      </Dialog>
      <WebSocketWrapper
        onMessageReceived={onMessageReceived}
        messageToSend={messageToSend}
        onConnectionChanged={onConnectionChanged}
        constants={constants}
      />

      <Routes>
        <Route path="/">
          <Route
            path="/virtualtour"
            element={
              <VirtualTourGrid
                {...props}
                setSelectedTourCode={setSelectedTourCode}
                setBackgroundVR={setBackgroundVR}
                setBgTrack={setBgTrack}
                setRotationVR={setRotationVR}
                setTourData={setTourData}
                setMenuActive={setMenuActive}
                currentImageUcode={currentImageUcode}
                setCurrentImageUcode={setCurrentImageUcode}
                instanceCode={instanceCode}
                constants={constants}
                setMessageToSend={setMessageToSend}
                lobbies={lobbies}
              />
            }
          />
          <Route
            path='/virtualtour/tour'
            element={
              <VirtualTour
                {...props}
                currentImageUcode={currentImageUcode}
                setCurrentImageUcode={setCurrentImageUcode}
                instanceCode={instanceCode}
                constants={constants}
                setMessageToSend={setMessageToSend}
                usersInLobby={usersInLobby}
                bgTrack={bgTrack}             
              />
            }
          />
         
          <Route
            {...props}
            path="/signin/*"
            element={
              <Signin constants={constants} setMessageToSend={setMessageToSend} />
            }
          />

        </Route>
      </Routes>
    </div>
  );
}

export default App;
