import React from "react";
import ModalContentText from "./ModalContentText";
import ModalContentVideo from "./ModalContentVideo";
import ModalContentSlideshow from "./ModalContentSlideshow";
import ModalContentQuiz from "./ModalContentQuiz";
import ModalFormInsertTutor from "./ModalFormInsertTutor";
import ModalFormInsertStudente from "./ModalFormInsertStudente";
import ButtonCloseX from "./ButtonCloseX";

const Modal = ({ constants, handleClose, handleConfirmButtonTutor, handleConfirmButtonStudente, show, sceneUcode, contentCode, type, hotspotUcode, nextSceneCallback, lobbies, tourType }) => {
    // HOTSPOTUCODE -> usato normalmente quando si clicca su hotspot, ma se il modale è un form (prima di entrare nel tour)
    // allora hotspotUcode contiene direttamente il tour code.
    // infatti la variabile tourCode controlla il "type", sotto il 5 sono tutti modali legati a hotspot (vedi SWITCH più sotto).

    const showHideClassName = show ? "block" : "hidden";

    let tourCode = type > 0 && type < 5 && hotspotUcode !== undefined ? hotspotUcode.substring(0, hotspotUcode.indexOf('_')) : hotspotUcode;

    const b64DecodeUnicode = (str) => {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    let decodedContent = contentCode !== undefined && contentCode !== null ? b64DecodeUnicode(contentCode) : "";

    const contentModal = () => {
        var contentToRender = "";
        switch (type) {
            case 1:
                //slideshow
                contentToRender = <ModalContentSlideshow content={decodedContent} constants={constants} tourCode={tourCode}></ModalContentSlideshow>
                break;
            case 2:
                //video
                contentToRender = <ModalContentVideo content={decodedContent} constants={constants} tourCode={tourCode}></ModalContentVideo>
                break;
            case 3:
                //text (contentCode è già il testo ma in base64)
                contentToRender = <ModalContentText content={decodedContent}></ModalContentText>
                break;
            case 4:
                //quiz            
                contentToRender = <ModalContentQuiz content={decodedContent} tourType={tourType} sceneCode={sceneUcode} handleClose={handleClose} nextSceneCallback={nextSceneCallback}></ModalContentQuiz>
                break;
            case 5:
                //FORM nome sessione del tour (MODALE CHE VEDE IL TUTOR)
                contentToRender = <ModalFormInsertTutor content={decodedContent} handleConfirmButton={handleConfirmButtonTutor}></ModalFormInsertTutor>
                break;
            case 6:
                //FORM nome utente (MODALE CHE VEDE LO STUDENTE)
                contentToRender = <ModalFormInsertStudente content={decodedContent} handleConfirmButton={handleConfirmButtonStudente} lobbies={lobbies}></ModalFormInsertStudente>
                break;
            default:

                return "";
        }
        return contentToRender;
    }


    return (
        <div className={`fixed bg-neutral-950/75 left-0 top-0 w-full h-full ${showHideClassName}`}>
            <ButtonCloseX
                classes="rounded-full bg-neutral-950 hover:bg-neutral-800 border-neutral-100 border-2 z-50 text-neutral-100 absolute top-10 right-10 p-3 h-12 w-12"
                handleClick={handleClose}>X</ButtonCloseX>

            <section className={`absolute bg-slate-50 h-auto w-auto max-w-5/6 max-h-4/5 p-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  rounded-xl overflow-y-scroll overflow-x-hidden`}>
                {contentModal()}
            </section>
        </div>
    );
}

export default Modal;