
import React from 'react';
import UserListItem from "./UserListItem";

const UserList = ({usersInLobby}) => {
  

    return (
        <div class={`fixed top-5 left-20 z-10 w-52 h-auto max-h-72 rounded-lg bg-black opacity-75 overflow-y-scroll`}>
          <ul class={`text-white text-lg ml-5 mt-2 mb-2`}>
            {
              usersInLobby.filter(x => x.userName !== "TUTOR").map(function (user) {
                return (
                    <UserListItem user={user}></UserListItem>
                );
              })}
          </ul>
        </div>
      
    )

};

export default UserList;
