import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import "aframe";
import AFRAME from 'aframe';
import 'aframe-html-shader';
import 'aframe-look-at-component';
import { Entity, Scene } from "aframe-react";
import Modal from './Modal';
import ModalReport from './ModalReport';
import axios from 'axios';
import moment from "moment";
import VideoJS from "./VideoJS";
import './css/videojs.css';

const THREE = AFRAME.THREE;

AFRAME.registerComponent('spot', {
  schema: {
    hsData: { type: "string", default: "" },
    description: { type: "string", default: "" },
    sceneUcode: { type: "string", default: "" },
    targetSceneUcode: { type: "string", default: "" },
    targetBackground: { type: "string", default: "" },
    type: { type: "int", default: 0 }
  },
  init: function () {
  }
});

class Photo extends Component {
  constructor(props) {
    super(props);

    /**
     * PROPS: (this.props)
     * backgroundVR
     * setBackgroundVR
     * rotationVR
     * setRotationVR
     * currentUcode
     * currentImageUcode
     * setCurrentImageUcode
     * tourData
     * constants
     * setTourRunning
     * setMessageToSend
     * openReport
     * setOpenReport
     */

    this.state = {
      hotspots: this.props.tourData.hotspots,
      clickedHotspot: "",
      contentModal: "",
      contentTypeModal: 0,
      sceneUcode: "",
      show: false,
      reportshow: false,
      redirect: false,
      jsonQuiz: ""
    };
    this.panImage = React.createRef();
    this.panVideo = React.createRef();
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModalReport = this.showModalReport.bind(this);
    this.hideModalReport = this.hideModalReport.bind(this);
  }

  // ELEMENTI MODALI E OVERLAY AL TOUR
  showModal = (type, cont, ucode, sceneUcode) => {
    this.setState({ show: true });
    this.setState({ contentModal: cont });
    this.setState({ contentTypeModal: type });
    this.setState({ clickedHotspot: ucode });
    this.setState({ sceneUcode: sceneUcode });
  };
  hideModal = () => {
    this.setState({ show: false });
    this.setState({ contentModal: "" });
    this.setState({ contentTypeModal: 0 });
    this.setState({ clickedHotspot: "" });
  };
  showModalReport = () => {
    this.setState({ reportshow: true });
    this.props.setOpenReport(true);
  };
  hideModalReport = () => {
    this.setState({ reportshow: false });
    this.props.setOpenReport(false);
  };
  showPreloader = () => {
    var preloader = document.querySelector('#preloader');
    preloader.style.visibility = "visible";
    preloader.style.opacity = 1;
    setTimeout(() => {
      console.log("attendo il caricamento...");
    }, 500);
    setTimeout(() => {
      if (preloader.style.visibility === "visible")
        this.hidePreloader();
    }, 5000);
  };
  hidePreloader = () => {
    var preloader = document.querySelector('#preloader');
    preloader.style.opacity = 0;
    setTimeout(() => {
        preloader.style.visibility = "hidden";
    }, 500);
    console.log("HIDE PRELOADER!");
  };

  sendQuizData = (jQuiz, username) => {
    if (jQuiz.length > 0) {
      const quizStartTime = localStorage.getItem("startTime"); //new Date(localStorage.getItem("startTime"));
      const quizEndTime = localStorage.getItem("endTime");//new Date(localStorage.getItem("endTime"));
      const sessionStartTime = localStorage.getItem("sessionStartTime");//new Date(localStorage.getItem("sessionStartTime"));
      let lobbyName = "";
      if (localStorage.getItem("lobbyName") !== null && localStorage.getItem("lobbyName") !== "")
        lobbyName = localStorage.getItem("lobbyName");
      const userInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));

      let quizArray = []; //è un array di json su base struttura QuizAnalyticsDto
      let jq = {};
      /**
         * RISPOSTE SALVATE
         * unanswered => l'utente ha cliccato su "SALTA" e quindi ha deciso di non ripondere alla domanda
         * "" => L'utente per qualche motivo è riuscito a saltare la domanda (magari è stato il tutor a fargliela saltare)
         * OK e KO => si spiegano da soli...
         */
      jQuiz.map((q) => {
        let status = 0;
        switch (q.status) {
          case "OK":
            status = 2;
            break;
          case "unanswered":
            status = 1;
            break;
          case "KO":
            status = 3;
            break;
          default:
            status = 0;
            break;
        }

        jq = {
          QuestionTitle: q.title,
          QuestionText: q.question,
          AnswerText: q.givenAnswer,
          QuestionState: status,
          AnswerTime: q.answerTime //new Date(q.answerTime)
        }
        quizArray.push(jq);
      });

      const jsonStringStats = {
        SessionAnalytics: {
          UCode: "",
          VirtualTourname: this.props.tourData.currentGallery,
          Username: userInfo[0],
          ExperienceType: this.props.tourData.type,
          LobbyName: lobbyName,
          LobbyUsername: username,
          SessionStartDate: sessionStartTime,
          QuizStartDate: quizStartTime,
          SessionEndDate: quizEndTime,
        },
        QuizAnalytics: quizArray
      }

      // INVIARE DATI PER LE STATISTICHE     
      let token = localStorage.getItem('userToken');
      //console.log(jsonStringStats);
      axios.post(this.props.constants.API_BASE_URL + '/api/AnalyticsWeb/', jsonStringStats, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        console.log(response)
      }).catch((e) => {
        console.log(e)
      });
      //FINE INVIO DATI STATISTICI

    }
  }

  nextSceneCallback = () => {
    const thisSceneUcode = this.state.clickedHotspot.substring(0, this.state.clickedHotspot.lastIndexOf("_"));
    const sceneIndex = this.props.tourData.images.findIndex((el) => el.uCode === thisSceneUcode);
    const nextScene = this.props.tourData.images[parseInt(sceneIndex) + 1];

    let targetSceneUcode = nextScene.uCode;
    let imagePath = nextScene.contentPath;
    let hs = this.props.tourData.hotspots.filter((el) => el.sceneUcode === nextScene.uCode);

    let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
    userLoggedInfo[4] = targetSceneUcode;
    localStorage.setItem("userLoggedInfo", JSON.stringify(userLoggedInfo));

    //REDIRECT ALLA SCENA SUCCESSIVA SOLO SE IL TOUR E' LIBERO. 
    //PER IL TOUR GUIDATO, CI PENSA IL TUTOR
    //MODIFICA FATTA 8/7/2024 A SEGUITO DI ESPLICITA RICHIESTA
    if (this.props.tourData.type === 0) {
      var videosrc = document.querySelector('.vjs-tech');
      if (videosrc !== null) {
        videosrc.pause();
        videosrc.setAttribute("src", "", "true");
      }

      //this.showPreloader();
      this.props.setBackgroundVR(this.props.constants.API_BASE_URL + "/Uploads/Corsi/" + nextScene.galleryUCode + "/" + imagePath.replace("..", ""));


      this.props.setCurrentImageUcode(targetSceneUcode);
      var camera = document.querySelector("a-camera");
      let cancelCameraRotation = THREE.MathUtils.radToDeg(camera.object3D.rotation.y); //Math.abs(THREE.MathUtils.radToDeg(camera.object3D.rotation.y));
      let newY = parseInt(nextScene.yaw) - 180 - cancelCameraRotation;
      this.props.setRotationVR(`0 ${newY} 0`);
      this.setState({ hotspots: hs });

      if (this.props.tourData.images.length !== parseInt(sceneIndex) + 2) {
        //ci sono scene successive a quella corrente
      } else {
        //non ci sono più scene!
        console.log("ultima scena");
        localStorage.setItem('endTime', moment(Date.now()).format());

        let username = localStorage.getItem("userName");
        if (username === null || username === undefined || username === "")
          username = "Tutor";

        this.setState({ jsonQuiz: localStorage.getItem('quiz') });

        let jQuiz = JSON.parse(localStorage.getItem('quiz'));
        console.log(jQuiz);
        this.sendQuizData(jQuiz, username);

        this.props.setTourRunning(false);
        this.showModalReport();
        //this.setState({ redirect: true });
      }
    }
  };
  // FINE ELEMENTI MODALI E OVERLAY AL TOUR


  // INIZIO funzioni usate per posizionare gli hotspot
  getCameraRotation(axis) {
    var rotation = this.props.rotationVR.split(' ');
    return parseInt(rotation[axis]);
  };
  reverseNum = (n) => {
    return (n * -1);
  }
  // FINE funzioni usate per posizionare gli hotspot


  getHotspotType = (t) => {
    let type = "hs_";
    switch (t) {
      case 0:
        type += "scene";
        break;
      case 1:
        type += "slide";
        break;
      case 2:
        type += "video";
        break;
      case 3:
        type += "text";
        break;
      case 4:
        type += "quiz";
        break;
      default:
        type += "base";
        break;
    }
    return type;
  };
  addEventToEachHotspot = (event) => {
    var sceneEl = document.querySelector('a-scene');
    if (sceneEl !== null) {
      var hsInScene = sceneEl.querySelectorAll('[spot]');
      if (hsInScene.length > 0) {
        hsInScene.forEach(element => {
          var hsID = element.getAttribute("id");
          var hsDetails = this.state.hotspots.filter((el) => el.ucode === hsID);

          element.addEventListener("click", event, false);
          element.hotspotDetails = hsDetails[0];
          if (hsDetails[0].description !== "") {
            element.addEventListener("mouseenter", (evt) => {
              const divtooltip = document.createElement('div');
              divtooltip.innerHTML = `<div id='tooltipScena' style='z-index:9999; color:white;background-color:rgba(0,0,0,0.5); position:absolute; left:0; top:96vh; width:auto; height:32px; padding:5px 25px; text-align:center'>${hsDetails[0].description}</div>`;
              element.appendChild(divtooltip);
            });

            element.addEventListener("mouseleave", (evt) => {
              const tooltipScena = document.getElementById("tooltipScena");
              if (tooltipScena) tooltipScena.remove();
            });
          }
        });
      }
    }
  };
  handleClickOnHotspot = (details) => {
    const tooltipScena = document.getElementById("tooltipScena");
    if (tooltipScena) tooltipScena.remove();

    if (details) {
      let targetSceneUcode = this.props.currentImageUcode;
      let hotspotData = details.currentTarget.hotspotDetails;

      if (hotspotData.type === 0) {
        //se è un cambio scena
        targetSceneUcode = hotspotData.targetSceneUcode;
        let imagePath = hotspotData.targetBackground;
        let scene = this.props.tourData.images.filter((el) => el.uCode === targetSceneUcode);
        let hs = this.props.tourData.hotspots.filter((el) => el.sceneUcode === targetSceneUcode);

        let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
        userLoggedInfo[4] = targetSceneUcode;
        localStorage.setItem("userLoggedInfo", JSON.stringify(userLoggedInfo));

        var videosrc = document.querySelector('.vjs-tech');
        if (videosrc !== null) {
          videosrc.pause();
          videosrc.setAttribute("src", "", "true");
        }

        this.props.setCurrentImageUcode(targetSceneUcode);
        var camera = document.querySelector("a-camera");
        let cancelCameraRotation = THREE.MathUtils.radToDeg(camera.object3D.rotation.y); //Math.abs(THREE.MathUtils.radToDeg(camera.object3D.rotation.y));
        let newY = parseInt(scene[0].yaw) - 180 - cancelCameraRotation;
        this.props.setRotationVR(`0 ${newY} 0`);
        this.setState({ hotspots: hs });
       
      } else {
        // se è un hotspot di tipo "contenuto"
        console.log(hotspotData);
        this.showModal(parseInt(hotspotData.type), hotspotData.data, hotspotData.ucode, hotspotData.sceneUcode);
      }
    }
  }
  dynamicCanvasCreation = () => {
    //CREAZIONE DEI CANVAS CHE RIEMPIONO LA DESCRIZIONE DEGLI HOTSPOT DI NAVIGAZIONE
    this.state.hotspots.map((hs) => {
      if (hs.description !== "") {
        let selector = 'targetHtml' + hs.ucode;
        var cvs = document.getElementById(selector);
        if (cvs) {
          var ctx = cvs.getContext("2d");
          ctx.fillStyle = "#ffffff"
          ctx.font = "60px Arial";
          ctx.textAlign = "center";
          ctx.fillText(hs.description, cvs.width / 2, 60, 280);
          ctx.fillStyle = "#000000"
          ctx.strokeText(hs.description, cvs.width / 2, 60, 280);
        }
      }
    });
  }

  //Replace dell'estensione del video per lo STREAMING
  replaceExtensionForStreaming = (filename) => {
    let newName = filename.toLowerCase();
    if (newName.lastIndexOf(".mp4") > 0) {
      newName = newName.replace(".mp4", ".m3u8");
    }
    return newName;
  }

  heightTooltip = 2.4;
  getMyTooltipHeight = (desc) => {
    if (desc.length > 11) {
      let quo = Math.floor(desc.length / 11);
      let rows = (this.heightTooltip * (quo + 1));
      return rows;
    } else {
      return this.heightTooltip;
    }
  }


  // MOUNT -> chiamato solo all'inizio (uso le props)
  componentDidMount() {
    //console.log("mount");
    let hs = this.props.tourData.hotspots.filter((el) => el.sceneUcode === this.props.currentImageUcode);
    const storedUserLevel = JSON.parse(localStorage.getItem("userLevel"));
    //se il tour è guidato e l'utente loggato è uno studente tolgo gli hotspot di navigazione (cambio scena)
    if (this.props.tourData.type === 1 && storedUserLevel !== null && storedUserLevel.includes("Studente"))
      hs = hs.filter((el) => el.type !== 0);


    this.setState({ hotspots: hs });
    //aggiungo evento per ogni hotspot nella scena
    this.addEventToEachHotspot(this.handleClickOnHotspot);

    let scena = this.props.tourData.images.filter((el) => el.uCode === this.props.currentImageUcode);
    this.props.setBackgroundVR(this.props.constants.API_BASE_URL + "/Uploads/Corsi/" + scena[0].galleryUCode + "/" + scena[0].contentPath);

    if (scena[0].contentPath.lastIndexOf(".mp4") > 0) {
      var skyboxVideosrc = document.querySelector('.vjs-tech');
      if (skyboxVideosrc !== null && skyboxVideosrc !== undefined) {
        skyboxVideosrc.addEventListener('loadeddata', function () {
          var preloader = document.querySelector('#preloader');
          preloader.style.opacity = 0;
          setTimeout(() => {
            preloader.style.visibility = "hidden";
          }, 500);
          console.log("CARICATO VIDEO!");
        });
      }
    } else {
      var skybox = document.querySelector('#skybox');
      if (skybox !== null && skybox !== undefined) {
        skybox.addEventListener('materialtextureloaded', function () {
          var preloader = document.querySelector('#preloader');
          setTimeout(() => {
            preloader.style.visibility = "hidden";
          }, 500);
          preloader.style.opacity = 0;
          //console.log("CARICATA!");
        });
      }
    }
  };
  // UPDATE -> chiamato per aggiornare i dati quando faccio un click, etc
  componentDidUpdate(prevProps) {
    console.log("UPDATE!");
    this.dynamicCanvasCreation();

    if (prevProps.openReport !== this.props.openReport && this.props.openReport === true) {
      this.showModalReport();
    }
    //eseguo solo se il codice della scena è cambiato (ALTRIMENTI VA IN LOOP)
    //currentImageUcode è in realtà il codice della scena, NON dell'immagine
    if (prevProps.currentImageUcode !== this.props.currentImageUcode) {
      // var videosrc = document.querySelector('#myvideo');
      // if (videosrc !== null) {
      //   videosrc.pause();
      //   videosrc.setAttribute("src", "", "true");
      // }

      let scena = this.props.tourData.images.filter((el) => el.uCode === this.props.currentImageUcode);
      let hs = this.props.tourData.hotspots.filter((el) => el.sceneUcode === this.props.currentImageUcode);
      const storedUserLevel = JSON.parse(localStorage.getItem("userLevel"));
      //se il tour è guidato e l'utente loggato è uno studente tolgo gli hotspot di navigazione (cambio scena)
      if (this.props.tourData.type === 1 && storedUserLevel.includes("Studente"))
        hs = hs.filter((el) => el.type !== 0);
      this.setState({ hotspots: hs });




      //currentImageUcodechange scene da parte del tutor, mi cambia 
      //quindi vado a cercare in tourdata qual è l'immagine da mettere come backgroundVR

      this.showPreloader();
      this.props.setBackgroundVR(this.props.constants.API_BASE_URL + "/Uploads/Corsi/" + scena[0].galleryUCode + "/" + scena[0].contentPath);

      console.log("BACKGROUND SCENA " + scena[0].contentPath);

      //Controllo se è l'ultima scena per aprire il report in caso di tour guidato
      if (this.props.tourData.type === 1) {
        const sceneIndex = this.props.tourData.images.findIndex((el) => el.uCode === scena[0].uCode);

        if (this.props.tourData.images.length === parseInt(sceneIndex) + 1) {
          //non ci sono più scene!
          localStorage.setItem('endTime', moment(Date.now()).format());

          let username = localStorage.getItem("userName");
          if (username === null || username === undefined || username === "")
            username = "Tutor";

          this.setState({ jsonQuiz: localStorage.getItem('quiz') });

          let jQuiz = JSON.parse(localStorage.getItem('quiz'));
          //console.log(jQuiz);
          if (username !== "Tutor") {
            this.sendQuizData(jQuiz, username);
            this.showModalReport();
          }
          this.props.setTourRunning(false);
        }
      }


      if (scena[0].contentPath.lastIndexOf(".mp4") > 0) {
        var skyboxVideosrc = document.querySelector('.vjs-tech');
        if (skyboxVideosrc !== null && skyboxVideosrc !== undefined) {
          skyboxVideosrc.addEventListener('loadeddata', function () {
            var preloader = document.querySelector('#preloader');
            preloader.style.opacity = 0;
            setTimeout(() => {
              preloader.style.visibility = "hidden";
            }, 500);
            console.log("CARICATO VIDEO!");
          });
        }
      } else {
        var skybox = document.querySelector('#skybox');
        if (skybox !== null && skybox !== undefined) {
          skybox.addEventListener('materialtextureloaded', function () {
            var preloader = document.querySelector('#preloader');
            setTimeout(() => {
              preloader.style.visibility = "hidden";
            }, 500);
            preloader.style.opacity = 0;
            //console.log("CARICATA!");
          });
        }
      }

    }
    //aggiungo evento per ogni hotspot nella scena
    this.addEventToEachHotspot(this.handleClickOnHotspot);
  };

  //ottengo ID del video JS (che genera automaticamente)
  //ma so il nome della classe, quindi lo frego così
  // l'ID mi serve come 'src' della videosphere
  getVideoJsIdByClassName = () => {
    const vjs = document.querySelector(".vjs-tech");
    const idGenerated = vjs.getAttribute("id");
    return "#" + idGenerated;
  }
  render() {
    const divStyleDrag = {
      display: 'block'
    };
    var videoUrl = "";
    var videoJsOptions="";
            
    
    if (this.props.backgroundVR.lastIndexOf(".mp4") > 0){
      videoUrl=this.replaceExtensionForStreaming(this.props.backgroundVR);
      videoJsOptions = {
        autoplay: true,
        controls: false,
        responsive: true,
        fluid: false,
        playsInline: true,
        sources: [{
          src: videoUrl,
          type: 'application/x-mpegURL'
        }]
      };
    }

    
    return (
      <>

        <div style={divStyleDrag}>
          <Scene loading-screen="dotsColor: #ef2d5e; backgroundColor: #000000;">
            {/* l'elemento myvideo deve stare dal di fuori di a-assets altrimenti a-videopshere non lo riesce a vedere */}
            {/* <video id="myvideo" src={this.replaceExtensionForStreaming(this.props.backgroundVR)} autoPlay type="application/x-mpegURL"></video> */}

            <VideoJS id="myvideo" options={videoJsOptions} />

            <a-assets>
              <img key="hs_base" id="hs_base" src={require('../assets/images/img_base.png')} alt="" />
              <img key="hs_scene" id="hs_scene" src={require('../assets/images/img_action.png')} alt="" />
              <img key="hs_slide" id="hs_slide" src={require('../assets/images/img_image.png')} alt="" />
              <img key="hs_video" id="hs_video" src={require('../assets/images/img_video.png')} alt="" />
              <img key="hs_text" id="hs_text" src={require('../assets/images/img_text.png')} alt="" />
              <img key="hs_info" id="hs_info" src={require('../assets/images/img_info.png')} alt="" />
              <img key="hs_quiz" id="hs_quiz" src={require('../assets/images/img_quiz.png')} alt="" />
              {this.state.hotspots.map((hs) => {
                let selector = 'targetHtml' + hs.ucode;
                return (
                  <React.Fragment key={hs.ucode}>
                    {
                      //hs.description !== "" ?
                      <canvas width="300" id={selector}></canvas>
                      //: null
                    }
                  </React.Fragment>
                );
              })}
            </a-assets>

            <a-entity id="spots">
              {
                this.state.hotspots.map((hs) => {
                  let selector = 'targetHtml' + hs.ucode; //usato per id dinamico (su fake tooltip che ora non c'è più)
                  return (
                    <React.Fragment key={hs.ucode}>
                      <a-curvedimage
                        spot={`hsData:${hs.data};description:${hs.description};sceneUcode:${hs.sceneUcode};targetSceneUcode:${hs.targetSceneUcode};targetBackground:${this.props.baseUrl + '/' + hs.targetBackground.replace("../", "")};type:${hs.type};`}
                        id={hs.ucode}
                        src={`#${this.getHotspotType(hs.type)}`}
                        rotation={`${this.reverseNum(hs.pitch) + this.getCameraRotation(0)} ${this.reverseNum(hs.yaw) + this.getCameraRotation(1)} 0`}
                        height="1"
                        theta-length="4"
                        radius="15"
                        scale="1 1 1" />

                      {/* la descrizione viene inserita dal componentDidUpdate che cerca i canvas generati (in a-assets) e li riempie
                        la seguente curved-image usa come SRC il selector dinamico che punta a un canvas generato appunto dinamicamente
                        solo se la descripion è presente.
                        */}
                      {hs.description !== "" ?
                        <>
                          <a-curvedimage
                            src={`#${selector}`}
                            rotation={`${this.reverseNum(hs.pitch) + this.getCameraRotation(0) + 7} ${this.reverseNum(hs.yaw) + this.getCameraRotation(1) - 18} 0`}
                            height={3}
                            theta-length="40"
                            radius="16"
                            scale="1 1 1" />
                        </>
                        : null
                      }
                    </React.Fragment>
                  );
                })}
            </a-entity>
            {/* <Entity id="skybox"
              ref={this.panImage}
              primitive="a-sky"
              theta-length="180"
              scale={{ x: -1 }}
              rotation={this.props.rotationVR}
              src={this.props.backgroundVR}>
              </Entity> */}
            {this.props.backgroundVR.lastIndexOf(".mp4") > 0
              ?
              // SRC della videosphere punta tramite id all'elemento html video che DEVE stare fuori da a-assets
              <a-videosphere id="skyboxVideo"
                ref={this.panVideo}
                rotation={this.props.rotationVR}
                src={this.getVideoJsIdByClassName()}>
              </a-videosphere>
              :
              <a-sky id="skybox"
                ref={this.panImage}
                theta-length="180"
                rotation={this.props.rotationVR}
                src={this.props.backgroundVR}>
              </a-sky>
            }


            <div id="preloader" style={{ position: 'absolute', backgroundColor: 'black', top: '0px', left: '0px', zIndex: '1000', width: '100%', height: '100vh', textAlign: 'center', display: 'flex', justifyContent: 'center', visibility: 'hidden', opacity: '0', transition: `opacity 500ms ease-in-out` }}>
              <p style={{ color: 'white', width: '250px', height: '50px', marginTop: '20%' }}>LOADING...</p>
            </div>

            <Entity id="camera_entity"
              primitive="a-camera"
              camera
              position="0 1 0"
              mouse-cursor
              look-controls={{ enabled: true, reverseMouseDrag: true, magicWindowTrackingEnabled: false }}
            >
              <a-entity
                id="a-cursor"
                cursor="fuse: true; fuseTimeout: 500; rayOrigin: mouse"
                position="0 1 -2"
                geometry="primitive: ring; radiusInner: 0; radiusOuter: 0"
                material="color: black; shader: flat"
                scale="0 0 0">
              </a-entity>
            </Entity>
          </Scene>

          <Modal id="modalContainer"
            constants={this.props.constants}
            show={this.state.show}
            handleClose={this.hideModal}
            contentCode={this.state.contentModal}
            type={this.state.contentTypeModal}
            hotspotUcode={this.state.clickedHotspot}
            sceneUcode={this.state.sceneUcode}
            nextSceneCallback={this.nextSceneCallback}
            tourType={this.props.tourData.type}
          >
          </Modal>

          <ModalReport id="modalReport"
            results={this.state.jsonQuiz}
            show={this.state.reportshow}
            handleClose={this.hideModalReport}>
          </ModalReport>
        </div>
      </>
    )
  }
};

export default Photo;
