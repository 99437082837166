
import React from 'react';

const UserListItem = ({user}) => { 
    return (
        <li key={user.connectionId}>{user.userName}</li>
    )
};

export default UserListItem;
