
import React from 'react';

const ButtonVR = ({
  backgroundVR,
  setBackgroundVR,
  setRotationVR,
  setCurrentImageUcode,
  menuActive,
  setMenuActive,
  tourData,
  constants,
  setMessageToSend
}) => {

  const changeSceneMsg = {
    type: "changescene",
    payload: {
      sceneCode: ""
    }
  }


  const handleCLick = (buttonTile) => {
    const imagePath = constants.API_BASE_URL + "/Uploads/Corsi/" + buttonTile.galleryUCode + "/" + buttonTile.contentPath;
    const rotation = `0 ${parseInt(buttonTile.yaw) - 180} 0`;
    setBackgroundVR(imagePath);
    setRotationVR(rotation);
    setCurrentImageUcode(buttonTile.uCode);

    // invia messaggio con il codice della scena da caricare
    changeSceneMsg.payload.sceneCode = buttonTile.uCode;
    setMessageToSend(JSON.stringify(changeSceneMsg));
    
    //setMenuActive(!menuActive);
  };

  const getBackground = (button) => {
    if (button.backgroundContentType === 0)
      return `url("${constants.API_BASE_URL}/Uploads/Corsi/${button.galleryUCode}/tn_${button.backgroundContentPath}")`;
    else
      return `url("${constants.API_BASE_URL}/Uploads/Corsi/${button.galleryUCode}/${button.backgroundContentPath}")`;
  }

  const buttons = tourData.images.map((button) => {
    return (
      <div
        key={button.uCode}
        className={`relative w-full h-[150px] min-w-[200px] flex flex-col items-center content-center justify-center bg-cover  bg-neutral-400 mr-4 ml-2 text-neutral-100 transition-all duration-500 ease-out box-border ${backgroundVR && backgroundVR.includes(button.backgroundContentPath) ? "border-4 border-neutral-50 grayscale-0" : " border-4 border-neutral-200/35 grayscale-[70%]"}`}
        onClick={() => menuActive && handleCLick(button)}
        style={{
          backgroundImage: getBackground(button)
        }}
      >
        <div className="z-[10000] cursor-pointer">
          <p className="z-20 font-normal text-sm uppercase font-sans text-neutral-100">{button.title}</p>
          </div>
        <div className="absolute w-full h-full top-0 left-0 bg-neutral-950/25 z-[2] cursor-pointer" />
      </div>
    );
  });

  return buttons;
};

export default ButtonVR;
