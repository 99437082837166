import React from 'react';

const TourPreviewTileCover = ({ Cover, TourCode, TourName, BaseUrl }) => {
    return (
        <>
            {Cover != null
                ? <img className="w-full" src={`${BaseUrl}/Uploads/Corsi/${TourCode}/${Cover}`} alt={TourName} />
                : <img className="w-full" src={`${BaseUrl}/Uploads/md_Photo_360.png`} alt={TourName} />}
        </>
    )
};

export default TourPreviewTileCover;
