import React from 'react';

const TourPreviewTileTags = ({ Type, Category }) => {
    return (
        <>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-neutral-800 mr-2 mb-2">{Category}</span>
            {
                Type === 0
                    ? <span className="inline-block bg-lime-300 rounded-full px-3 py-1 text-sm font-semibold text-neutral-800 mr-2 mb-2">Tour libero</span>
                    : <span className="inline-block bg-amber-300 rounded-full px-3 py-1 text-sm font-semibold text-neutral-800 mr-2 mb-2">Tour guidato</span>
            }
        </>
    )
};

export default TourPreviewTileTags;
