import React from "react";
import './css/modal.css';
import '../../node_modules/video-react/dist/video-react.css';
import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Button, Typography } from "@material-ui/core";

const ModalContentSlideshow = ({ constants, tourCode, content }) => {
  const baseUrl = constants.API_BASE_URL + "/Uploads/Corsi/" + tourCode + "/";
  const jsonContent = JSON.parse(content);
  const slides = jsonContent.Items;

  //riordino le slide
  slides.sort(function (a, b) {
    return a.Sorting - b.Sorting;
  });
  return (
    <React.Fragment>
      <Typography variant="h5" style={{textAlign:'center'}} gutterBottom>{jsonContent.Title}</Typography>
      <Carousel
        infiniteLoop={true}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        dynamicHeight={true}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div>
              <Button onClick={clickHandler} type="button" aria-label="next slide / item" class="control-arrow control-prev custom-control"></Button>
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div>
              <Button onClick={clickHandler} type="button" aria-label="next slide / item" class="control-arrow control-next custom-control"></Button>
            </div>
          );
        }}
      >
        {
          slides.map(function (slide) {
            return (
              <img key={slide.FileName} src={`${baseUrl}${slide.FileName} `} alt={slide.FileName} style={{ objectFit: 'contain', aspectRatio: 16 / 9 }} />
            );
          })
        }
      </Carousel>
    </React.Fragment>
  );
}

export default ModalContentSlideshow;