import React, { useEffect, useState } from "react";
import Menu from './Menu';
import Photo from './Photo';
import AudioContent from "./AudioContent";
import { useLocation } from "react-router-dom";

const VirtualTour = ({ setMessageToSend, currentImageUcode, setCurrentImageUcode, instanceCode, usersInLobby, bgTrack }) => {
    let location = useLocation();
    //const [isAdminOrTutor, setIsAdminOrTutor] = useState(false);
    const [backgroundVR, setBackgroundVR] = useState(location.state.backgroundVR);
    const [menuActive, setMenuActive] = useState(location.state.menuActive);
    const [rotationVR, setRotationVR] = useState(location.state.rotationVR);
    const [tourData, setTourData] = useState(location.state.config);
    const [tourRunning, setTourRunning] = useState(location.state.tourRunning);
    const [backgroundTrack, setBackgroundTrack] = useState('');
    const [otherTrack, setOtherTrack] = useState('');
    const [otherTrackIsVoiceOver, setOtherTrackIsVoiceOver] = useState(false);
    const [otherTrackIsLoop, setOtheTrackIsLoop] = useState(false);
    const [openReport, setOpenReport] = useState(false);

    // eseguito una volta
    useEffect(() => {
        setBackgroundTrack(location.state.constants.API_BASE_URL + "/Uploads/Corsi/" + location.state.tourcode + '/' + bgTrack);
    }, []);

    //eseguito ad ogni cambio scena
    useEffect(() => {
        if (currentImageUcode !== null && currentImageUcode !== "") {
            let jsonScene = tourData.images.filter(i => i.uCode === currentImageUcode);

            setOtherTrackIsVoiceOver(false);
            setOtheTrackIsLoop(false);
            setOtherTrack(null);
            //console.log(jsonScene);
            if (jsonScene[0].audio !== undefined && jsonScene[0].audio !== "") {
                setOtherTrack(location.state.constants.API_BASE_URL + "/Uploads/Corsi/" + location.state.tourcode + "/" + jsonScene[0].audio);
                setOtheTrackIsLoop(jsonScene[0].audioLoop);

                //0 = replace; 1= voiceOver;
                if (jsonScene[0].audioPlayMode === 1) setOtherTrackIsVoiceOver(true);
            }
        }
    }, [backgroundVR]);

    return (
        <>
            <Photo
                backgroundVR={backgroundVR}
                setBackgroundVR={setBackgroundVR}
                rotationVR={rotationVR}
                setRotationVR={setRotationVR}
                currentImageUcode={currentImageUcode}
                setCurrentImageUcode={setCurrentImageUcode}
                tourData={tourData}
                constants={location.state.constants}
                setTourRunning={setTourRunning}
                setMessageToSend={setMessageToSend}
                openReport={openReport}
                setOpenReport={setOpenReport}
            />

            <Menu
                backgroundVR={backgroundVR}
                setBackgroundVR={setBackgroundVR}
                setRotationVR={setRotationVR}
                currentImageUcode={currentImageUcode}
                setCurrentImageUcode={setCurrentImageUcode}
                menuActive={menuActive}
                setMenuActive={setMenuActive}
                tourData={tourData}
                constants={location.state.constants}
                setMessageToSend={setMessageToSend}
                instanceCode={instanceCode}
                usersInLobby={usersInLobby}
                tourRunning={tourRunning}
                setOpenReport={setOpenReport}
            />

            <AudioContent
                backgroundTrack={backgroundTrack}
                otherAudio={otherTrack}
                isVoiceOver={otherTrackIsVoiceOver}
                isLoop={otherTrackIsLoop}
            />
        </>
    )
}

export default VirtualTour;