import React, { useEffect, useState } from "react";
import ButtonVR from "./ButtonVR";
import UserList from "./UserList";
import { useNavigate } from "react-router-dom";
const Menu = ({
  backgroundVR,
  setBackgroundVR,
  setRotationVR,
  instanceCode,
  usersInLobby,
  currentImageUcode,
  setCurrentImageUcode,
  menuActive,
  setMenuActive,
  tourData,
  constants,
  tourRunning,
  setMessageToSend,
  setOpenReport
}) => {
  const [isAdminOrTutor, setIsAdminOrTutor] = useState(false);
  const [visibleRep, setVisibleRep] = useState(false);
  const navigate = useNavigate();

  const handleCLick = () => {
    setMenuActive(!menuActive);
  };
  const exitTour = () => {

    var videosrc = document.querySelector('.vjs-tech');
    if (videosrc !== null) {
      videosrc.pause();
      videosrc.setAttribute("src", "", "true");
    }

    const exit = {
      "type": "exittour",
      "payload": {}
    }
    const end = {
      "type": "endtour",
      "payload": { istanceCode: instanceCode }
    }

    setMessageToSend(JSON.stringify(exit));
    localStorage.removeItem("quiz");
    localStorage.removeItem("tourData");
    localStorage.removeItem("startTime");
    localStorage.removeItem("endTime");
    localStorage.removeItem("refresh");
    localStorage.removeItem("userName");

    let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
    userLoggedInfo[2] = ""; //codice tour
    userLoggedInfo[3] = ""; //lobby
    userLoggedInfo[4] = ""; //codice scena
    localStorage.setItem("userLoggedInfo", JSON.stringify(userLoggedInfo));

    // se è tutor o admin -> invio anche ENDTOUR
    if (isAdminOrTutor)
      setMessageToSend(JSON.stringify(end));

    navigate('/virtualtour', { state: { constants: constants } });
  }

  const openReport = () => {
    console.log("open report");
    setOpenReport(true);
  }

  const userList = () => {
    if (usersInLobby.filter(x => x.userName !== "TUTOR").length > 0) {
      return (
        <UserList usersInLobby={usersInLobby} />
      );
    }
    else
      return null
  }

  // eseguito una volta
  useEffect(() => {
    const storedUserLevel = JSON.parse(localStorage.getItem("userLevel"));
    if (storedUserLevel !== null) {
      if (storedUserLevel.includes("Studente"))
        setIsAdminOrTutor(false);
      else
        setIsAdminOrTutor(true);
    }
  }, []);

  useEffect(() => {
    //tourRunning viene messa a false se sono nella scena finale (quella con il report)
    //il setTourRunning è in Photos.jsx

    //mostra o nasconde l'icona-button "R" per aprire/nascondere il report -> compare solo nell'ultima scena
    if (!tourRunning)
      setVisibleRep(true);
    else
      setVisibleRep(false);

  }, [tourRunning]);


  return (
    <>
      <div className={`fixed top-5 left-5 z-10 w-14 h-10 cursor-pointer flex justify-center border-black border-2 bg-white rounded-2xl opacity-75`}
        onClick={exitTour}>
        <p className={`text-black text-center text-xl self-center`}> ESCI </p>
      </div>
      <div className={`fixed top-20 left-5 z-10 w-10 border-black border-2 h-10 cursor-pointer flex justify-center bg-yellow-400 rounded-full ${visibleRep ? "block" : "hidden"}`}
        onClick={openReport}>
        <p className={`text-black text-center text-2xl self-center`}> R </p>
      </div>

      {
        isAdminOrTutor ?
          <>
            {userList()}
            <div className={`fixed p-4 bottom-0 z-10 overflow-x-hidden w-full flex flex-col justify-end content-end items-center transition-all duration-500 bg-gradient-to-t from-neutral-950/40 to-neutral-950/0 ${!menuActive && "translate-y-[130px]"}`}>
              <div
                className={`transition-all cursor-pointer border-neutral-100 border-l-2 border-t-2 border-b-0 border-r-0 w-8 h-8 ${!menuActive ? "rotate-45 mb-0" : "rotate-225 mb-4"}`}
                onClick={handleCLick}
              />
              <div className="overflow-y-hidden overflow-x-hidden flex flex-row w-full items-end">
                <div className="flex flex-row w-full items-end overflow-auto">
                  <ButtonVR
                    backgroundVR={backgroundVR}
                    setBackgroundVR={setBackgroundVR}
                    setRotationVR={setRotationVR}
                    setCurrentImageUcode={setCurrentImageUcode}
                    menuActive={menuActive}
                    setMenuActive={setMenuActive}
                    tourData={tourData}
                    constants={constants}
                    setMessageToSend={setMessageToSend}
                  />
                </div>
              </div>
            </div>
          </>
          : null
      }
    </>
  );
};

export default Menu;
