import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioContent = ({ backgroundTrack, otherAudio, isVoiceOver, isLoop }) => {
    
    //nel caso in cui otherAudio sia in modalità voiceOver
    //abbasso leggermente il volume dell'audio in background 
    const getBgVolume = () => {
        if (otherAudio !== null && otherAudio !== "")
            if (isVoiceOver === true)
                return 0.8;
            else
                return 0;
        else
            return 1;

    }

     //NB -> non uso play e pausa perché il componente react-audio-player non ha queste funzioni
    // quindi stupidamente metto a 0 il volume e lo riporto a 1 quando l'audio è presente.
    const getOtherVolume = () => {
        if (otherAudio !== null && otherAudio !== "" )
            return 1;
        else
            return 0;
    }


    return (
        <>
            <ReactAudioPlayer
                src={backgroundTrack}
                autoPlay
                volume={getBgVolume()}
                loop
            />

            <ReactAudioPlayer
                id={"otherPlayer"}
                src={otherAudio}
                autoPlay
                loop={isLoop ? true : false}
                volume={getOtherVolume()}
            />
        </>
    )
}

export default AudioContent;