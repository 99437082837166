import React, { useState, useEffect, useRef } from 'react';
import './css/modal.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import Modal from "./Modal";
import TourPreviewTileCover from './TourPreviewTileCover';
import TourPreviewTileTags from './TourPreviewTileTags';

const TourPreviewTile = ({ Constants, TourCode, SetSelectedTourCode, SetBackgroundVR, SetMenuActive, SetRotationVR, SetBgTrack, BgTrack, SetTourData, TourName, Category, Type, Cover, CurrentImageUcode, SetCurrentImageUcode, SetMessageToSend, lobbies, instanceCode }) => {
    const navigate = useNavigate();
    const isFetched = useRef(false);
    //const isModalRequired = useRef(false);
    const [isShowModal, setIsShowModal] = useState(false);
    //const [isGuidato, setIsGuidato] = useState(false);
    const [contentModal, setContentModal] = useState();
    const [contentTypeModal, setContentTypeModal] = useState();
    const [tourSelectedModal, setTourSelectedModal] = useState();
    const [backgroundVR, setBackgroundVR] = useState(Constants.API_BASE_URL + "/Uploads/placeholder.jpg");
    const [menuActive, setMenuActive] = useState(false);
    const [rotationVR, setRotationVR] = useState("0 0 0");
    const [stateUpdated, setStateUpdated] = useState(false);
    const [tourData, setTourData] = useState({
        loading: false,
        currentGallery: "",
        images: [],
        hotspots: [],
    });

    function getConfigFromLocalstorage() {
        console.warn("Cerco dati in locale");
        if (localStorage.getItem("tourData")) {
            // prendo i dati dal localstorage
            const data = localStorage.getItem("tourData");
            const structuredData = JSON.parse(data);
            setTourData({
                currentGallery: structuredData.galleries,
                images: structuredData.images,
                hotspots: structuredData.hotspots,
                loading: false
            });
            // imposta il primo sfondo
            const image = structuredData.images[0];
            const imagePath = Constants.API_BASE_URL + "/Uploads/Corsi/" + image.galleryUCode + "/" + image.backgroundContentPath;
            setBackgroundVR(imagePath);
        }
    }

    async function getGalleryImages(galleryCode) {

        setTourData({
            ...tourData,
            loading: true
        });

        let remoteImages = [];
        let hotspots = [];
        let quiz = [];
        let token = localStorage.getItem('userToken');
        // acquisisco le immagini della gallery specifica
        axios.get(Constants.API_BASE_URL + '/api/virtualtour/' + galleryCode, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {

            remoteImages = response.data.scenes;
            hotspots = response.data.hotSpots;
            quiz = response.data.quiz;
            //console.log(response.data);
            // creo oggetto che salvo in localstorage
            const localData = {
                currentGallery: galleryCode,
                type: response.data.experienceType,
                images: remoteImages,
                hotspots: hotspots,
                backgroundTrack: response.data.backgroundTrack
            }
            localStorage.setItem("tourData", JSON.stringify(localData));

            let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
            userLoggedInfo[2] = galleryCode;
            localStorage.setItem("userLoggedInfo", JSON.stringify(userLoggedInfo));

            //salvo nello stato tutti i dati raccolti, e imposto il flag per indicare che ho finito
            setTourData({
                currentGallery: galleryCode,
                type: response.data.experienceType,
                images: remoteImages,
                hotspots: hotspots,
                backgroundTrack: response.data.backgroundTrack,
                loading: false
            });

            //aggiungo la proprietà STATUS e SCENEUCODE per ogni quiz
            const quizArray = [];
            if (quiz !== undefined && quiz.length > 0) {
                for (let q of quiz) {
                    for (let h of hotspots) {
                        if (h.type === 4) {

                            const b64DecodeUnicode = (str) => {
                                // Going backwards: from bytestream, to percent-encoding, to original string.
                                return decodeURIComponent(atob(str).split('').map(function (c) {
                                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                                }).join(''));
                            }
                            let dataContentHotspot = b64DecodeUnicode(h.data);

                            let contentHotspot = JSON.parse(dataContentHotspot);
                            if (contentHotspot.UCode === q.uCode) {
                                q.sceneCode = h.sceneUcode;
                            }
                        }
                    }
                    q.status = "";
                    q.givenAnswer = "";
                    q.answerTime = "";
                    quizArray.push(q);
                }
                localStorage.setItem("quiz", JSON.stringify(quizArray));
            }

            // imposta il primo sfondo
            const image = localData.images[0];
            const imagePath = Constants.API_BASE_URL + "/Uploads/Corsi/" + image.galleryUCode + "/" + image.backgroundContentPath;

            setBackgroundVR(imagePath);
            setRotationVR(`0 ${parseInt(image.yaw) - 180} 0`);
            SetBgTrack(response.data.backgroundTrack);
            SetCurrentImageUcode(image.uCode);
            isFetched.current = true;

            if (response.data.experienceType === 0) {
                localStorage.setItem('sessionStartTime', moment(Date.now()).format());
                localStorage.setItem("lobbyName", ""); // reset in caso precedentemente abbia fatto un tour guidato.
                SetSelectedTourCode(TourCode);
                SetTourData(tourData);
                SetMenuActive(menuActive);
                setStateUpdated(true);
            }
            else {
                const storedUserLevel = JSON.parse(localStorage.getItem("userLevel"));
                if (storedUserLevel.includes("Studente"))
                    showModal(6, null, galleryCode);
                else
                    showModal(5, null, galleryCode);
            }


        }).catch((ex) => {
            console.log("catch");
            console.log(ex);
            getConfigFromLocalstorage();
        });


    };
    function goToTour() {
        //REDIRECT -> TOUR 360
        navigate('/virtualtour/tour',
            {
                state: {
                    constants: Constants,
                    tourcode: TourCode,
                    backgroundVR: backgroundVR,
                    currentImageUcode: CurrentImageUcode,
                    rotationVR: rotationVR,
                    menuActive: menuActive,
                    config: tourData,
                    tourRunning: true,
                }
            });
    };
    const hideModal = () => {
        setIsShowModal(false);
        setContentModal();
        setContentTypeModal();
        setTourSelectedModal();
    };
    const showModal = (type, cont, ucode) => {
        setIsShowModal(true);
        setContentModal(cont);
        setContentTypeModal(type);
        setTourSelectedModal(ucode);
    };

    const selectTour = () => {
        //nella funzione getGalleryImage viene settata la ref isFetched a true
        //che viene poi controllata nello useEffect e quando è TRUE -> fa partire il tour.
        getGalleryImages(TourCode);
    };

    const onInsertSessionName = (e) => {
        // Prevent the browser from reloading the page
        e.preventDefault();
        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        const sessionName = formJson.sessionName;
        const storedCustomerCode = localStorage.getItem("customerCode");

        const lobby = {
            "type": "createlobby",
            "payload": {
                customerCode: storedCustomerCode,
                virtualTourCode: TourCode,
                description: sessionName
            }
        }

        SetMessageToSend(JSON.stringify(lobby));

        SetSelectedTourCode(TourCode);
        SetBackgroundVR(backgroundVR);
        SetRotationVR(rotationVR);
        SetTourData(tourData);
        SetMenuActive(menuActive);
        localStorage.setItem('sessionStartTime', moment(Date.now()).format());
        localStorage.setItem('lobbyName', sessionName);
        hideModal();

    };
    const onInsertUserName = (e) => {
        // Prevent the browser from reloading the page
        e.preventDefault();
        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        const username = formJson.username;
        const instancecode = formJson.rbInstances;

        if (username !== '') {
            if (instancecode !== undefined) {

                localStorage.setItem('userName', username);

                const joinLobby = {
                    "type": "join",
                    "payload": {
                        instanceCode: instancecode,
                        userName: username
                    }
                }

                SetMessageToSend(JSON.stringify(joinLobby));

                let userLoggedInfo = JSON.parse(localStorage.getItem("userLoggedInfo"));
                userLoggedInfo[3] = instancecode;
                localStorage.setItem("userLoggedInfo", JSON.stringify(userLoggedInfo));

                SetSelectedTourCode(TourCode);
                SetBackgroundVR(backgroundVR);
                SetRotationVR(rotationVR);
                SetTourData(tourData);
                SetMenuActive(menuActive);
                localStorage.setItem('sessionStartTime', moment(Date.now()).format());
                // LA LOBBY NAME VIENE SALVATA NEL MODAL FROM INSERT STUDENTE -> onchange del radiogroup
                hideModal();

                setStateUpdated(true); //per eseguire il goToTour
            } else {
                alert("Selezionare una sessione!");
            }
        } else {
            alert("Inserire un nome utente");
        }
    };

    useEffect(() => {
        if (stateUpdated)
            goToTour();

    }, [stateUpdated]);

    return (

        <>
            <div className="cursor-pointer max-w-sm min-w-[25%] rounded-xl overflow-hidden shadow-[0.625rem_0.625rem_0.875rem_0_rgb(20,20,20),-0.5rem_-0.5rem_1.125rem_0_rgb(45,45,45)] border-[1px] border-stone-500 transition ease-in-out duration-300 hover:shadow-[0.725rem_0.725rem_0.975rem_0_rgb(20,20,20),-0.6rem_-0.6rem_1.225rem_0_rgb(45,55,45)] hover:bg-gray-900 hover:border-stone-300"
                onClick={selectTour}>
                <TourPreviewTileCover Cover={Cover} TourCode={TourCode} TourName={TourName} BaseUrl={Constants.API_BASE_URL} />
                <div className="px-6 py-4">
                    <h4 className="text-2xl font-bold text-neutral-100">{TourName}</h4>                   
                </div>
                <div className="px-6 pb-2 flex justify-between">
                    <TourPreviewTileTags Category={Category} Type={Type} />
                </div>
            </div>

            <Modal id="modalForm"
                constants={Constants}
                show={isShowModal}
                handleClose={hideModal}
                contentCode={contentModal}
                type={contentTypeModal}
                hotspotUcode={tourSelectedModal}
                handleConfirmButtonTutor={onInsertSessionName}
                handleConfirmButtonStudente={onInsertUserName}
                lobbies={lobbies}>
            </Modal>
        </>
    );
}

export default TourPreviewTile;