import React, { useRef, useState, useEffect } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Button, FormHelperText } from "@material-ui/core";
import moment from 'moment';

const ModalContentQuiz = ({ tourType, sceneCode, content, handleClose, nextSceneCallback }) => {
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const jsonContent = JSON.parse(content);
    const quiz = useRef();
    const value = useRef();
    var shuffledArray = useRef([]);
    var storedValue = useRef("");
    var reference = useRef("");
    var question = useRef("");
    var thisQuizStatus = useRef("");
    var answerClicked = useRef(false);

    const handleChange = (e) => {
        storedValue.current = e;
    };
    const checkAnswer = () => {
        let rispOk = false;
        if (jsonContent.AnswerOk === storedValue.current) rispOk = true;

        return rispOk;
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (storedValue.current !== "") {
            //domanda con risposta impostata
            if (storedValue.current !== "unanswered") {
                if (checkAnswer()) {
                    thisQuizStatus.current = "OK";
                    setHelperText('RISPOSTA CORRETTA!');
                    setError(false);
                    storeAndTransition();
                } else {
                    thisQuizStatus.current = "KO";
                    setHelperText('RISPOSTA ERRATA!');
                    setError(true);
                    storeAndTransition();
                }
            } else {
                //ha cliccato su "SALTA"
                thisQuizStatus.current = "unanswered";
                setHelperText('');
                setError(false);
                storeAndTransition();
            }
        } else {
            //ha cliccato su "CONFERMA" ma non ha selezionato una risposta
            setHelperText('Devi selezionare una risposta!');
            setError(true);
        }
    };

    const jumpQuestion = (e) => {
        storedValue.current = "unanswered";
        handleSubmit(e);
    }


    const storeAndTransition = () => {
        answerClicked.current = true;
        //store data in cookie   

        const allQuiz = JSON.parse(localStorage.getItem('quiz'));
        //console.log(allQuiz.filter((q) => q.sceneCode === sceneCode)[0]);
        allQuiz.filter((q) => q.sceneCode === sceneCode)[0].status = thisQuizStatus.current;
        allQuiz.filter((q) => q.sceneCode === sceneCode)[0].givenAnswer = storedValue.current;
        allQuiz.filter((q) => q.sceneCode === sceneCode)[0].answerTime = moment(Date.now()).format();

        localStorage.setItem("quiz", JSON.stringify(allQuiz));

        //transizione verso la scena successiva
        setTimeout(() => {
            setError(false);
            setHelperText('');
            thisQuizStatus.current = "";
            shuffledArray.current = []
            value.current = "";
            storedValue.current = "";
            reference.current = "";
            question.current = "";
            handleClose();
            nextSceneCallback();
        }, 2000);
    }

    const shuffle = (array) => {
        return array.map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
    };
    const answers = () => {
        reference.current = jsonContent.Reference;
        question.current = jsonContent.Question;
        let arr = new Array();
        arr[0] = new Array();
        arr[1] = new Array();
        arr[2] = new Array();

        arr[0][0] = jsonContent.AnswerKo1;
        arr[0][1] = false;
        arr[1][0] = jsonContent.AnswerKo2;
        arr[1][1] = false;
        arr[2][0] = jsonContent.AnswerOk;
        arr[2][1] = true;

        shuffledArray.current = shuffle(arr);

        return shuffledArray.current;
    }

    useEffect(() => {
        const allQuiz = JSON.parse(localStorage.getItem('quiz'));
        const thisQuiz = allQuiz.filter((q) => q.sceneCode === sceneCode)[0];

        const startTime = localStorage.getItem('startTime');

        if (startTime === undefined || startTime === null)
            localStorage.setItem('startTime', moment(Date.now()).format());

        thisQuizStatus.current = thisQuiz.status;
        quiz.current = thisQuiz;
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <FormControl>
                <h2 class="font-bold my-3 text-xl">{jsonContent.Question}</h2>
                {
                    thisQuizStatus.current === ""
                        ?
                        answerClicked.current === false ?
                            <>
                                <RadioGroup
                                    id="rb_quiz"
                                    name={jsonContent.Title}
                                    defaultValue="unanswered"
                                    ref={value}>
                                    <FormControlLabel class={'invisible'} key={"-"} value={"unanswered"} control={<Radio />} label={"unanswered"} onChange={(e) => handleChange(e.target.value)} />
                                    {answers().map(function (risp) {
                                        return (
                                            <FormControlLabel key={risp} value={risp[0]} control={<Radio />} label={risp[0]} onChange={(e) => handleChange(e.target.value)} />
                                        );
                                    })}
                                </RadioGroup>
                                <Button sx={{ mt: 1, mr: 1 }} type="submit" class='bg-sky-700 hover:bg-sky-600 py-2 mt-4 rounded-2xl text-neutral-100 w-full'>
                                    Conferma
                                </Button>
                                {tourType === 1
                                    ?
                                    null
                                    :
                                    <Button sx={{ mt: 1, mr: 1 }} onClick={jumpQuestion} class='text-sky-700 hover:text-sky-600 py-2 mt-4 rounded-2xl w-full'>
                                        Salta
                                    </Button>
                                }
                            </>
                            :
                            null
                        :
                        answerClicked.current === false ?
                            <>
                                <p>Hai già risposto o hai deciso di saltare questa domanda.</p>
                                <Button sx={{ mt: 1, mr: 1 }} onClick={() => { nextSceneCallback(); handleClose(); }} class='text-sky-700 hover:text-sky-600 py-2 mt-4 rounded-2xl w-full'>
                                    Avanti
                                </Button>
                            </>
                            :
                            null
                }
                <FormHelperText class={`my-4 mx-0  ${error ? 'text-red-700' : 'text-sky-700'}`}>{helperText}</FormHelperText>

            </FormControl>
        </form>
    );
}

export default ModalContentQuiz;