import React from "react";
import './css/modal.css';
import '../../node_modules/video-react/dist/video-react.css';
import { Player } from "video-react";
import VideoJS from "./VideoJS";


const ModalContentVideo = ({ constants, tourCode, content }) => {
    const playerRef = React.useRef(null);
    const jsonContent = JSON.parse(content);

    let videoUrl = constants.API_BASE_URL + "/Uploads/Corsi/" + tourCode + "/" + jsonContent.Filename.replace(".mp4", ".m3u8")
    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: false,
        playsInline : true,
        width:960,
        height:540,
        sources: [{
            src: videoUrl,
            type: 'application/x-mpegURL'
        }]
    };
    const handlePlayerReady = (player) => {
        playerRef.current = player;        
    };

    return (
        // <Player playsInline autoPlay fluid={false} width={960} height={540}>
        //     <source src={videoUrl} type="application/x-mpegURL" />
        // </Player>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    );
}

export default ModalContentVideo;